.content__title {
  font-family: var(--font-family-bold);
  font-size: 24px;
  color: var(--primary);
  margin: 12px 0;
}


@media (max-width: 767px) {
  .download__app {
    width: 100%;
    background: #106b55;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999999;
  }
  
  .download__app__text {
    color: #FFFF;
    line-height: 1.1;
    font-size: 14px;
    padding: 0 30px 0 0;
  }
  
  .download__app__link {
    width: 100%;
    max-width: 100px;
    padding: 5px;
    background-color: #000;
    border-radius: 5px;
    color: #FFF;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .download__app {
    display: none;
  }
}


.main__title {
  color: #342E37;
  text-align: center;
  font-family: var(--font-family-bold);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 22px 0 12px 0;
}

.alert {
  margin-top: 12px;
}

.form__wrap {
  margin-top: 20px;
  width: 60%;
}

.image__container {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.home__image {
  border-radius: 8px;
}

.cupom__wrapp {
  position: relative;
  width: 48%;
}

.cupom__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: flex;
  height: 28px;
  padding: 12px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #106B55;
  color: var(--Text-color-White, #F1F1F1);
  font-family: var(--font-family);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.wrapp__container {
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
}

/* .hr {
  margin: 57px 0 24px 0;
} */

@media (min-width: 768px) {
  .group__location {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 24px;
  }

  .group__period {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  .next__button {
    display: block;
    width: 166px !important;
  }
}

.submit_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 768px) {
  .main__title {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .form__inputs {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }

  .group__location {
    width: 65%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 24px;
  }

  .group__period {
    width: 35%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  .image__container__mob {
    display: none;
  }
}

@media (max-width: 1024px) {
  .hr {
    display: none;
  }

  .form__wrap {
    width: 100%;
  }

  .cupom__btn {
    display: none;
  }

  .wrapp__container {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cupom__wrapp {
    width: 100%;
  }

  .image__container {
    display: none;
  }
}


