.formGroup {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.label {
  display: block;
  font-family: var(--font-family-semibold);
  font-size: 16px;
  color: #342e37;
  margin-bottom: 8px;
}

.formGroup__error {
  margin-top: 12px;
  font-size: 14px;
  color: var(--primary);
}
