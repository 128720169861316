.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999999;
}

.modal__body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-width: 636px;
  max-height: 700px;
  padding: 18px 24px;
  background: #fff;
  transform: translate(-50%, -50%);
  border: 1px solid #f2f4f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 99999999;
}

.modal__content {
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: auto;
}

.modal__action {
  display: flex;
  gap: 12px;
}

.modal__action button {
  height: 48px;
}

.title {
  font-family: var(--ff-bold);
  font-size: 24px;
  color: var(--primary);
  margin-bottom: 18px;
}

.modal__body__content {
  font-size: 16px;
  color: #342e37;
}

.modal__body__content p {
  margin-bottom: 0.3rem;
}

.content__divider {
  width: 100%;
  height: 2px;
  background: rgb(223, 223, 223);
  margin: 24px 0;
  border: none;
  opacity: 0.25;
}
