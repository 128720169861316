.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 56px;
  border-radius: var(--border-radius);
  border: none;
  outline: none;

  font-family: var(--font-family-semibold);
  font-size: 16px;
  text-align: center;
  color: #ffffff;

  cursor: pointer;
}

.button[disabled] {
  background-color: var(--disabled);
  cursor: not-allowed;
}

.danger {
  background: #f20000;
}

.success {
  background: var(--success);
}

.gray {
  background: #444;
}

.button.isIconRight svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.button.iconLeft svg {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}